<template>
  <div>
    <b-button variant="primary"
              size="sm"
              v-b-modal="`preview${row.macltalk_summary_srl}`"
    >
      조회 및 발송취소
    </b-button>

    <b-modal centered
             :id="`preview${row.macltalk_summary_srl}`"
             title="메시지 상세 보기"
             body-class="p-0 m-0"
             ok-title="닫기"
             ok-variant="secondary"
             size="custom1"
             ok-only
    >
      <div class="modal-body" style="min-height: 300px;">

        <!--<div class="row" style="height: 100%">-->

        <div style="background-color: #b2c7da !important; border-radius: 0.25rem 0.25rem 0 0; height: 100%; min-height: 300px; padding-top: 13px;">

          <div v-if="msgType == 'text'" style="position: relative;">

            <div style="display: inline-block; position: absolute; left:0; top:0;">
              <img :src="require('@/assets/mcimages/asset_kakao_profile.svg')"
                   style="margin-left: 13px; width:40px;">
            </div>

            <div style="display: inline-block; padding: 0 64px 0 70px; width: 100%">
              <p style="margin-bottom: 9px; !important;">{{ this.logged_info.mart_name }}</p>

              <div style="background-color: white; padding: 16px; width: 100%" class="style-alarm-talk">
                <div style="position: relative;">

                  <p v-html="textHtml" style="margin-bottom: 0">
                  </p>

                </div>
              </div>
            </div>

          </div>

          <div v-if="msgType == 'single'" style="position: relative;">

            <div style="display: inline-block; position: absolute; left:0; top:0;">
              <img :src="require('@/assets/mcimages/asset_kakao_profile.svg')"
                   style="margin-left: 13px; width:40px;">
            </div>

            <div style="display: inline-block; padding: 0 64px 0 70px; width: 100%">
              <p style="margin-bottom: 9px; !important;">{{ this.logged_info.mart_name }}</p>

              <div style="width:280px; background-color: white; margin-right: 0;" class="style-alarm-talk">
                <div style="text-align: center; position: relative; padding-top:10px;">
                  <div style="position:relative; margin-top:10px; border-radius: 1px;
                            background-size:cover; width:280px; height:280px;"
                       :style="{'background-image':`url(${this.row.msg.target_msg.img})`}"
                  >
                  </div>
                  <div
                      style="width:100%; padding-top: 2px; padding-bottom: 2px; padding-right: 8px; text-align: right; display: inline-block;">
                  </div>
                  <p style="margin:0; line-height:140%; font-size: 1em; word-break:break-all; text-align: left; padding: 0 3%;">
                    {{ row.msg.target_msg.name }}
                  </p>

                  <p style="margin:0; line-height:140%; font-size: 1em; word-break:break-all; text-align: left; padding: 0 3%;">
                          <span id="single_product_price" style=" color:black;">
                            {{ row.msg.target_msg.price }}원
                          </span>
                    <span id="single_product_pre_price"
                          style="font-size: 0.9em; padding-left:5px; color:black; text-decoration:line-through"
                    >
                          {{ row.msg.target_msg.pre_price }}원
                          </span>
                    <span id="single_product_percent"
                          style="font-size: 0.9em; color:red; padding-left:5px;"
                    >
                          {{ row.msg.target_msg.percent }}%
                          </span>
                  </p>

                  <div style="margin:8px;">
                    <!--                          <b-button block variant="light" size="sm" style="padding:.8em; margin: 0;">자세히 보기</b-button>-->
                    <b-button disabled size="sm"
                              class="btn btn-soft-secondary w-100"
                              style="padding: .8em; background-color: #efefef; color: black; border-color: #dddddd">
                      자세히 보기
                    </b-button>
                  </div>
                  <div
                      style="position:relative; width:100%; height: 45px; margin:0 auto; padding:0 0 0 3%;">
                    <div
                        style="position:absolute; left:0; width:100%; margin:0 auto; padding:10px 10px 10px 3%; text-align:left">
                      크리톡
                    </div>
                    <div style="position:absolute; right:0; padding:10px 3% 0 0; text-align:left">></div>
                  </div>

                </div>
              </div>
            </div>

          </div>

          <div v-if="msgType === 'list'" style="position: relative">

            <div style="display: inline-block; position: absolute; top:0; left: 0;">
              <img :src="require('@/assets/mcimages/asset_kakao_profile.svg')"
                   style="margin-left: 13px; width:40px;">
            </div>

            <div style="display: inline-block; padding-left: 70px;">
              <p style="margin-bottom: 9px; !important;">{{ this.logged_info.mart_name }}</p>

              <div
                  style="width:280px; background-color: white; margin-right: 0;"
                  class="style-alarm-talk">
                <div>
                  <div
                      style="width:100%; margin:0 auto; padding:10px 10px 10px 3%; border-bottom: 1px solid #ddd; text-align:left">
                    {{ this.logged_info.mart_name }}
                  </div>
                  <div
                      style="position:relative; width:100%; height:100px; margin:0 auto; padding:10px 10px 10px 3%; border-bottom: 1px solid #ddd; text-align:left">
                    <table style="width: 100%">
                      <tr>
                        <td style="vertical-align: top;">
                          <p style="margin:0; padding:0; font-size: 1em; word-break:normal;">
                            {{ row.msg.target_msg[0].name.replaceAll('_', ' ') }}
                          </p>
                          <p style="margin:0; padding:0; font-size: 1em; word-break:break-all; color:#888;">
                            {{
                              row.msg.target_msg[0].pre_price == '' ? row.msg.target_msg[0].pre_price : row.msg.target_msg[0].price | comma
                            }}원
                          </p>
                        </td>
                        <td style="width: 80px;">
                          <div
                              style="background-size:cover; right:10px; width:80px; height:80px;"
                              :style="{'background-image':`url(${this.row.msg.target_msg[0].img})`}"
                          />
                        </td>
                      </tr>
                    </table>

                    <div style="padding-top: 2px; text-align: right; display: inline-block;">
                      <b-button variant="light" size="sm" class="btn btn-soft-secondary"
                                style="margin-right:0; position: absolute; right: 1px; bottom: 1px; width: 22px; height:22px; padding: 0; text-align: center;"
                      >
                        <feather type="settings" size="16"></feather>
                      </b-button>
                    </div>
                  </div>


                  <div
                      style="position:relative; width:100%; height:100px; margin:0 auto; padding:10px 10px 10px 3%; border-bottom: 1px solid #ddd; text-align:left">
                    <table style="width: 100%">
                      <tr>
                        <td style="vertical-align: top;">
                          <p style="margin:0; padding:0; font-size: 1em; word-break:normal;">
                            {{ row.msg.target_msg[1].name.replaceAll('_', ' ') }}
                          </p>
                          <p style="margin:0; padding:0; font-size: 1em; word-break:break-all; color:#888;">
                            {{
                              row.msg.target_msg[1].pre_price == '' ? row.msg.target_msg[1].pre_price : row.msg.target_msg[1].price | comma
                            }}원
                          </p>
                        </td>
                        <td style="width: 80px;">
                          <div
                              style="background-size:cover; right:10px; width:80px; height:80px;"
                              :style="{'background-image':`url(${this.row.msg.target_msg[1].img})`}"
                          />
                        </td>
                      </tr>
                    </table>

                    <div style="padding-top: 2px; text-align: right; display: inline-block;">
                      <b-button variant="light" size="sm" class="btn btn-soft-secondary"
                                style="margin-right:0; position: absolute; right: 1px; bottom: 1px; width: 22px; height:22px; padding: 0; text-align: center;"
                      >
                        <feather type="settings" size="16"></feather>
                      </b-button>
                    </div>
                  </div>


                  <div
                      style="position:relative; width:100%; height:100px; margin:0 auto; padding:10px 10px 10px 3%; border-bottom: 1px solid #ddd; text-align:left">
                    <table style="width: 100%">
                      <tr>
                        <td style="vertical-align: top;">
                          <p style="margin:0; padding:0; font-size: 1em; word-break:normal;">
                            {{ row.msg.target_msg[2].name.replaceAll('_', ' ') }}
                          </p>
                          <p style="margin:0; padding:0; font-size: 1em; word-break:break-all; color:#888;">
                            {{
                              row.msg.target_msg[2].pre_price == '' ? row.msg.target_msg[2].pre_price : row.msg.target_msg[2].price | comma
                            }}원
                          </p>
                        </td>
                        <td style="width: 80px;">
                          <div
                              style="background-size:cover; right:10px; width:80px; height:80px;"
                              :style="{'background-image':`url(${this.row.msg.target_msg[2].img})`}"
                          />
                        </td>
                      </tr>
                    </table>

                    <div style="padding-top: 2px; text-align: right; display: inline-block;">
                      <b-button variant="light" size="sm" class="btn btn-soft-secondary"
                                style="margin-right:0; position: absolute; right: 1px; bottom: 1px; width: 22px; height:22px; padding: 0; text-align: center;"
                      >
                        <feather type="settings" size="16"></feather>
                      </b-button>
                    </div>
                  </div>

                  <div style="position:relative; width:100%; height: 45px; margin:0 auto; padding:0 0 0 3%;">
                    <div
                        style="position:absolute; left:0; width:100%; margin:0 auto; padding:10px 10px 10px 3%; text-align:left">
                      크리톡
                    </div>
                    <div style="position:absolute; right:0; padding:10px 3% 0 0; text-align:left">></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="msgType == 'custom_single'" style="position: relative;">

            <div style="display: inline-block; position: absolute; left:0; top:0;">
              <img :src="require('@/assets/mcimages/asset_kakao_profile.svg')"
                   style="margin-left: 13px; width:40px;">
            </div>

            <div style="display: inline-block;padding: 0 64px 0 70px; width: 100%">
              <p style="margin-bottom: 9px; !important;">{{ this.logged_info.mart_name }}</p>

              <div style="width:280px; background-color: white; margin-right: 0;"
                   class="style-alarm-talk">
                <div style="text-align: center; position: relative; padding-top:10px;">
                  <div style="position:relative; margin-top:10px; border-radius: 1px;
                            background-size:cover; width:280px; height:280px;"
                       :style="{'background-image':`url(${this.row.msg.target_msg.img})`}"
                  >
                  </div>
                  <div
                      style="width:100%; padding-top: 2px; padding-bottom: 2px; padding-right: 8px; text-align: right; display: inline-block;">
                  </div>
                  <p style="margin:0; line-height:140%; font-size: 1em; word-break:break-all; text-align: left; padding: 0 3%;">
                    {{ row.msg.target_msg.name }}
                  </p>

                  <p style="margin:0; line-height:140%; font-size: 1em; word-break:break-all; text-align: left; padding: 0 3%;">
                    {{ row.msg.target_msg.price }}
                  </p>


                  <div style="margin:8px;">
                    <b-button disabled size="sm"
                              class="btn btn-soft-secondary w-100"
                              style="padding: .8em; background-color: #efefef; color: black; border-color: #dddddd">
                      자세히 보기
                    </b-button>
                  </div>
                  <div
                      style="position:relative; width:100%; height: 45px; margin:0 auto; padding:0 0 0 3%;">
                    <div
                        style="position:absolute; left:0; width:100%; margin:0 auto; padding:10px 10px 10px 3%; text-align:left">
                      크리톡
                    </div>
                    <div style="position:absolute; right:0; padding:10px 3% 0 0; text-align:left">></div>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
        <b-button
            block
            style="width:100%; border-radius: 0 0 0.25rem 0.25rem;"
            :disabled="row.percent == '100.00%'"
            @click="sendCancel"
        >
          {{ setBtnTitle() }}
        </b-button>

      </div>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'

import {mapState} from "vuex";
import {loggedInfoObject} from "@/state/helpers";
import axios from "axios";
import {msgConfirmOptCenter} from "@/components/globalFunctions";

export default Vue.extend({
  name: "msgPreviewBtn",
  data: function () {
    return {
      row: {},
      msgType: '',
      textHtml: '',
    };
  },
  beforeMount() {
    this.row = this.getValueToDisplay(this.params)

    if (typeof this.row.msg.sending_msg_type == 'undefined' || this.row.msg.sending_msg_type == null || this.row.msg.sending_msg_type == '') {
      this.msgType = 'text'
      this.textHtml = this.row.msg.replaceAll('\n', '<br/>')
    } else {
      this.msgType = this.row.msg.sending_msg_type
      /*this.msgContent.push(this.row.msg.target_msg)*/
    }
  },

  computed: {
    ...mapState(loggedInfoObject),
  },

  filters: {
    comma(val) {
      return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  },

  methods: {

    getValueToDisplay(params) {
      // console.log('----------------------------------')
      // console.log('data : ',params.data)
      // console.log('value : ', params.value)
      // console.log('formatted', params.valueFormatted)
      // console.log('----------------------------------')
      //return params.valueFormatted ? params.valueFormatted : params.value;
      return params.data
    },

    //test
    /*    showData() {
          console.log('asdlfjkasdl;fjasdl;fj', this.row)
        },*/

    //return button title
    setBtnTitle() {
      if (this.row.percent == '100.00%') {
        return '발송이 완료되어 취소할 수 없습니다.'
      } else {
        return '발송취소'
      }
    },

    //send cancel
    sendCancel() {
      this.$bvModal.msgBoxConfirm('발송취소 후 수정이 불가합니다.\n발송을 취소하시겠습니까?', msgConfirmOptCenter)
          .then((sel) => {
            if (sel) {
              let url = `/api/macltalk/cancel/status/n/${this.row.macltalk_summary_srl}`

              axios.put(url)
                  .then(() => {
                    //console.log(res.data)

                    this.params.context.componentParent.callMaclTalkHistory()
                  })
                  .catch(err => console.log(err))
            }
          })
    },
  }
})
</script>

<style scoped>
.style-alarm-talk {
  display: inline-block;
  border-radius: 16px !important;
  margin-right: 20px;
  vertical-align: top;
  margin-bottom: 20px;
}

.style-alarm-talk p.title {
  background: #ffeb34 !important;
  position: relative;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}

.style-alarm-talk p.title img {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 34px;
}

</style>

<style>
.modal .modal-custom1 {
  max-width: 480px;
  width: 480px;
}
</style>
