<script>
import Layout from "@/views/layouts/main.vue";
import PageHeader from "@/components/page_header_new.vue";
import appConfig from "@/app.config.json";

import {AgGridVue} from "ag-grid-vue";
import msgPreview from "@/views/(duplicate)/maclTalk/msgPreview.vue"

import {mapState} from "vuex";
import {loggedInfoObject} from "@/state/helpers";
import axios from "axios";

export default {
  page: {
    title: "마클톡 발송내역",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "마클톡 발송내역",
      items: '마클톡 전송 내역을 확인할 수 있습니다.',
      historyList: [],
      gridApi: null,
      columnApi: null,
      columnDefs: [
        {headerName: '발송일시', field: 'set_time'},
        {headerName: '발송(예약)일시', field: 'rsrv_time'},
        {headerName: '메시지 조회', field: 'msg', cellRendererFramework: msgPreview},
        {headerName: '진행률', field: 'percent'}
      ],
      context: {},
      rowData: {},
      msgType: '',

    };
  },
  components: {
    Layout,
    PageHeader,
    AgGridVue,
  },
  computed: {
    ...mapState(loggedInfoObject),
  },
  beforeMount() {
    //ag-grid 부모 자식간 통신을 위해 필요함
    this.context = {
      componentParent: this
    }
  },
  mounted() {
    this.callMaclTalkHistory()
  },

  methods: {
    //ag-grid methods
    onFirstDataRendered(event) {
      event.api.sizeColumnsToFit()
    },
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.$refs.default_input.value)
    },
    onGridReady(params) {
      this.gridApi = params.api
    },
/*    onSelectionChanged() {
      const selectedRow = this.gridApi.getSelectedRows();
      console.log(selectedRow[0])
      this.rowData = selectedRow[0]

      if (typeof selectedRow[0].msg.sending_msg_type == 'undefined' || selectedRow[0].msg.sending_msg_type == null || selectedRow[0].msg.sending_msg_type == '') {
        this.msgType = 'text'
        this.msgContent.push({
          'msg': selectedRow[0].msg.replaceAll('\n', '<br/>')
        })
      } else {
        this.msgType = selectedRow[0].msg.sending_msg_type
        this.msgContent.push(selectedRow[0].msg.target_msg)
      }
      //console.log('msg type', this.msgContent[0])
      //console.log('msgType', this.msgType)
      this.$bvModal.show('modal-center')
    },*/

    callMaclTalkHistory() {
      let url = `/api/macltalk/history/sendtalk/${this.logged_info.mart_srl}`

      axios.get(url).then((res) => {
        //console.log(res.data)

        this.historyList = res.data
      }).catch(err => console.log(err))
    },

    initMsgContent() {
      this.msgContent = []
      console.log('msgContent', this.msgContent)
    }

  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <div class="card">
      <div class="card-body">
        <ag-grid-vue
            style="height: calc(100vh - 340px); width: 100%; text-align: center;"
            class="ag-theme-alpine"
            :columnDefs="columnDefs"
            :rowData="historyList"
            :context="context"
            @grid-ready="onGridReady"
            @first-data-rendered="onFirstDataRendered"
            :cacheQuickFilter="true"
            suppressHorizontalScroll="false"
            enableCellTextSelection="true"
        ></ag-grid-vue>
      </div>
    </div>
  </Layout>
</template>

<style>
.ag-header-cell-label {
  justify-content: center;
}

.ag-cell {
  border-right: 1px solid !important;
  border-top: 0 !important;
  border-color: #f4f4f4 !important;
}

</style>
